.picture__prompt-container {
  background: rgb(174 174 174 / 75%);
  overflow: auto;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  z-index: 100000;
}
.picture__prompt-box {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 3rem;
  padding: 2rem;
  border-radius: 1.5rem;
}

.snap__picture-promp--text {
  font-size: 1.5rem;
}

.snap__picture-now {
  width: 100%;
  /* width: 429.23px; */
  height: 56px;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
}

.upload__picture-later {
  width: 100%;
  height: 56px;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 48rem) {
  .picture__prompt-container {
    padding: 0rem 1.5rem;
  }

  .picture__prompt-box {
    width: 100%;
  }
}
