.vacs-and-healthtips__container {
  padding: 5.4rem 3.6rem 10rem 3.6rem;
  background: #f9f9f9;
}

.vacs-and-healthtips__headinng {
  color: #242424;
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 5.6rem;
}

.tip__content-switch--container {
  padding: 2rem 0rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #242424;
  margin-bottom: 4rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.tip__switch-CTA--inactive {
  color: #7c7c7c;
  font-size: 1.8rem;
  font-weight: 400;
  background: none;
}
.tip__switch-CTA--active {
  color: #242424;
  font-size: 1.8rem;
  font-weight: 700;
  background: none;
}

.vaccination__tips-container {
}

.vac__defination-boxes--container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
}

.vaccine__defination-box {
  /* flex: 1; */
  padding: 3rem 1.5rem;
  margin-bottom: 2rem;
  background-color: #fff;
  background-image: url(../../assets/images/vaccine-def-img.png);
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #fff;
  width: 100%;
}
.vaccination__defination-box {
  /* flex: 1; */
  padding: 3rem 1.5rem;
  background-color: #fff;
  width: 100%;
}

.vaccination__defination-heading--text {
  color: #242424;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.vaccination__defination-body--text {
  color: #242424;
  font-size: 1.3rem;
  font-weight: 400;
}

.common__vacs-container {
}

.common__vacs-heading {
  color: #242424;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.common__vacs-line {
  border-radius: 30px;
  background: rgba(231, 231, 231, 0.71);
  width: 3.5rem;
  height: 3.3rem;
  width: 100%;
  margin-bottom: 3rem;
}

.common__vacs-boxes {
}

.common__vaccine-box {
  padding: 3rem 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid rgba(47, 128, 237, 0.2);
  background: #fff;
}

.common__vaccine-box:not(:last-child) {
  margin-bottom: 1.8rem;
}

.common__vaccine-name {
  color: #242424;
  font-size: 1.4rem;
  font-weight: 700;
  margin-right: 2.5rem;
  margin-bottom: 1.5rem;
}

.common__vaccine-desc {
  color: #7c7c7c;
  font-size: 1.4rem;
  font-weight: 400;
}

@media screen and (min-width: 36rem) {
  .vaccination__defination-body--text {
    max-width: 44rem;
  }
}
@media screen and (min-width: 48rem) {
  .vac__defination-boxes--container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 4rem;
  }

  .vaccine__defination-box {
    margin-bottom: 0;
    margin-right: 2rem;
    background-size: 100%;
    background-position: top;
  }
  .vaccination__defination-box {
    margin-left: 2rem;
  }
}

@media screen and (min-width: 62rem) {
  .vacs-and-healthtips__container {
    padding: 7rem 4rem;
  }
}

@media screen and (min-width: 62rem) {
  /* .vacs-and-healthtips__container {
    padding: 7rem 6rem;
  } */

  .vaccination__defination-body--text {
    font-size: 1.6rem;
    max-width: 39.8rem;
  }

  .vaccine__defination-box {
    margin-bottom: 0;
    margin-right: 1rem;
    background-position: top;
    padding: 5.5rem 4rem;
  }
  .vaccination__defination-box {
    margin-left: 1rem;
    padding: 5.5rem 4rem;
  }

  .vaccination__defination-heading--text {
    color: #242424;
    font-size: 2.4rem;
    font-weight: 700;
  }

  .vacs-and-healthtips__headinng {
    font-size: 4rem;
    font-weight: 700;
  }

  .tip__content-switch--container {
    padding-left: 5rem;
    padding-right: 5rem;
    border-bottom: 1.5px solid #cccccc;
  }

  .tip__switch-CTA--active {
    color: #2f80ed;
    font-size: 2.4rem;
    font-weight: 700;
  }

  .tip__switch-CTA--inactive {
    font-size: 2.4rem;
    font-weight: 700;
  }

  .common__vacs-line {
    height: 6rem;
    margin-bottom: 4rem;
  }

  .common__vacs-heading {
    color: #242424;
    font-size: 2.4rem;
    font-weight: 700;
  }

  .common__vacs-boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .common__vaccine-box {
    flex-direction: column;
    width: 48%;
    align-items: flex-start;
    padding: 2.7rem 1.5rem 2.7rem 3.9rem;
    border-radius: 2rem;
    margin-bottom: 4.7rem !important;
    background-color: #fff;
  }

  .common__vaccine-name {
    color: #242424;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .common__vaccine-desc {
    color: #7c7c7c;
    font-size: 1.8rem;
    font-weight: 400;
  }
}
