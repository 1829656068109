.panel__container {
  display: flex;
  /* background-color: cadetblue; */
  overflow: hidden;
}

/*LEADERBOARD*/
@media screen and (max-width: 48rem) /*768/16*/ {
  .panel__container {
    flex-direction: column-reverse;
  }
}
