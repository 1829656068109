.addChild__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: 100vh;
  /* height: 100%; */
}

.addUserForm__container {
  /* height: 100vh; */
  /* background-color: cadetblue; */
}

.addUserForm__content--container {
  background: rgb(174 174 174 / 75%);
  /* backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(0.7rem); */
  overflow: auto;
  /* height: 100%; */
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
}

.title-and-close {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #d885a3;
}

.navLogo-and-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  height: 8%;
  /* background-color: gold; */
}

.addUser__heading-and-close--CTA {
  display: flex;
  width: 100%;
  /* background-color: #655d8a; */
  align-items: center;
  justify-content: space-between;
}

.addChild__email--input {
  width: 100%;
  height: 4.8rem;
  padding-left: 2rem;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.added__parent-span {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.added__parent-text {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #07532a;
  margin-right: 1rem;
}

.add_parent--CTA {
  padding: 1rem;
  width: fit-content;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  border-radius: 1.5px;
  margin: 2rem 0;
}

.addChild__email--input:focus {
  outline: none !important;
  border: 1px solid #d885a3;
}

.addUserForm__form--container {
  width: 50%;
  /* background: #fbf2f5; */
  padding: 3rem 0rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.addUser__logo-span {
  width: 100%;
}

.add-user__form-and-text__container {
  height: fit-content;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

.addUserForm__form {
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.mail__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
}

.addUserForm__email-and-icon {
  position: relative;
}

.addUserForm__password-and-icons {
  position: relative;
}

.lock__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
}

.eye__icon {
  position: absolute;
  top: 1.5rem;
  left: 38.5rem;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.addUserForm__form--texts {
  display: flex;
  flex-direction: column;
  width: fit-content;
  /* background-color: aquamarine; */
}

.addUserForm__formheading {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 1rem;
  color: rgb(26, 115, 232);
}

.addUserForm__form-sub--text {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #858585;
}

.addUserForm__email--label {
  font-weight: 300;
  font-size: 12px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.addUserForm__password--label {
  font-weight: 300;
  font-size: 12px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.addUserForm__email--input {
  width: 429.23px;
  height: 48px;
  padding-left: 2rem;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}
.email--input {
  width: 429.23px;
  height: 48px;
  padding-left: 5rem;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.email--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
}
.addUserForm__email--input:focus {
  outline: none !important;
  border: 1px solid #d885a3;
}

.addUserForm__firstName--input {
  padding-left: 2rem;
  width: 429.23px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.addUserForm__password--input:focus {
  outline: none !important;
  border: 1px solid #d885a3;
}

.password--input {
  padding-left: 5rem;
  width: 429.23px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.password--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
}

.addUserForm__submit--CTA {
  width: 429.23px;
  height: 56px;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
  margin-top: 4rem;
}

.addUserForm__submit--CTA:hover {
  transform: translateY(-0.3rem);
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem,
    rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.addUserForm__image--container {
  height: 100%;
  background-color: #655d8a;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addUserForm__main--text {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  margin-top: 7rem;
  margin-bottom: 2rem;
}

.addUserForm__sub--text {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 3rem;

  color: #f8f8f8;
}

@media screen and (max-width: 65rem) /*104/16 = 65*/ {
  .addUserForm__form--container {
    padding: 3rem 3rem 3rem 6rem;
  }

  .addChild__email--input {
    width: 360.23px;
  }
  .email--input {
    width: 360.23px;
  }

  .addUserForm__password--input {
    width: 360.23px;
  }
  .password--input {
    width: 360.23px;
  }

  .addUserForm__submit--CTA {
    width: 360.23px;
  }

  .eye__icon {
    left: 32.5rem;
  }
}
@media screen and (max-width: 54.8rem) /*877/16 = 65*/ {
  .addUserForm__content--container {
    display: block;
  }

  .addUserForm__form--container {
    width: 100%;
    height: 100%;
  }

  .addUserForm__image--container {
    display: none;
  }

  .form-and-text__container {
    height: 92%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 28.8rem) /*877/16 = 65*/ {
  .addUserForm__form--container {
    padding: 3rem 1rem 3rem 1rem;
    height: 70vh;
  }

  .addUserForm__content--container {
    display: block;
  }

  .addUserForm__form--container {
    width: 100%;
    height: 100%;
  }

  .addChild__email--input {
    width: 300.23px;
  }
  .email--input {
    width: 300.23px;
  }

  .addUserForm__password--input {
    width: 300.23px;
  }
  .password--input {
    width: 300.23px;
  }

  .addUserForm__submit--CTA {
    width: 100%;
  }

  .eye__icon {
    left: 26.5rem;
  }

  .addUserForm__image--container {
    display: none;
  }

  .form-and-text__container {
    display: flex;
  }
}
