.table__container {
  background-color: #fff;
  width: 100%;
  border-radius: 1rem;
  padding: 0 3rem;
  max-height: 71rem;
  min-height: 40rem;
  overflow-y: auto;
  margin-top: 3rem;
  margin-bottom: 8rem;
  box-shadow: 0px 4px 40px rgb(221 221 221 / 30%);
  overflow-x: auto;
}

.action__tD {
  position: relative;
}

.actions__span {
  position: absolute;
  top: 3rem;
  left: -28%;
  width: 148px;
  height: 78px;
  background: #ffffff;
  border: 0.7px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(216, 213, 213, 0.1);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0px 4px 40px rgb(221 221 221 / 20%);
}

.action__option {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  text-align: center;
  color: #4a5567;
  text-align: left;
}

.see__full-table--details {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 3rem 0rem;
  padding-right: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: underline;
}

.see__table-data--text {
  cursor: pointer;
}

/*Custom Scrollbar*/

/* width */
::-webkit-scrollbar {
  /* width: 1rem; */
  width: 2.5px;
  height: 2.5px;

  /* margin: 2rem 0; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #c6cbd2;
  opacity: 0.4;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4a5567;
  border-radius: 4px;
}

.table__title--container {
  width: auto;
  min-width: 46rem;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(255, 255, 255);
  /* height: 8rem; */
  margin-bottom: -4.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  /* margin-left: 2rem; */
}

.table__title {
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
  font-weight: 600;
}

.add__user-icon {
  margin-right: 1rem;
}

.addChild__CTA {
  width: fit-content;
  padding: 0 2rem;
  height: 4rem;
  background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
  /* border: 1px solid #e5e5e5; */
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.addChild__CTA:hover {
  transform: translateY(-0.3rem);
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem,
    rgb(233 30 98 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  padding-left: 2rem;
  white-space: nowrap;
  overflow-x: auto;

  z-index: -1;
  margin-top: 1.5rem;
}

.table__header--row {
  /* background-color: darkblue; */
  height: 5rem;
}

.child__name {
  color: black;
}

td,
th {
  /* border-top: 1px solid #dddddd; */
  /* border-bottom: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
  font-family: "Sora";
}

th {
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  color: rgb(123, 128, 154);
  padding-top: 6rem;
  border-top: none;
}

tr {
  border-bottom: 0.7px solid #f2f5f9;
}

td {
  font-size: 1.3rem;
  font-weight: 600;
  color: #949699;
}

@media screen and (max-width: 48rem) {
}
