.facilities__filter__graph__container {
  width: 100%;
  position: relative;
  padding-top: 29rem;
  margin-bottom: 10rem;
  /* background-color: red; */
}

.facilities__filter__graph__filters {
  position: absolute;
  /* z-index: 10; */
  top: 0;
  display: flex;
  /* background-color: aqua; */
  width: 100%;
}

.filter__param-span-2 {
  display: flex !important;
  flex-direction: column;
  /* align-items: flex-end; */
}

.input__span-onn {
  width: 20rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.filter__param-input-2 {
  padding: 1rem;
  width: 100%;
}

.filter__CTA-2 {
  margin-top: 0rem;
  width: 20rem;
  /* height: 3rem; */
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 48rem) {
  .facilities__filter__graph__container {
    padding-top: 6rem;
  }

  .filter__param-span-2 {
    flex-direction: row;
    align-items: flex-end;
  }

  .input__span-onn {
    margin-bottom: 0rem;
  }

  .filter__CTA-2 {
    width: 20%;
  }
}
