.filter__params-container {
  width: 100%;
  /* background-color: red; */
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.filter__param-span {
  display: flex;
  flex-direction: column;
  min-width: 20%;
}

.filter__param-label {
  font-size: 1.35rem;
  margin: 1rem;
}

.filter__param-input {
  width: 100%;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #dce4ec;
}

.children__table--row {
  border: 10px solid #f2f5f9;
}

.pagination__controls-box {
  /* background-color: red; */
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.pagination__controls {
  display: flex;
  align-items: center;
  /* background-color: aqua; */
  /* width: 10%; */
}

.pagination__CTA {
  font-size: 1.3rem;
  cursor: pointer;
}

.filter__CTA-span {
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
}

.filter__CTA {
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
  margin-top: 4rem;
}

@media screen and (max-width: 48rem) {
  .filter__param-span {
    width: 100%;
  }
}
