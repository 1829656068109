.data__management--container {
  background-color: #f0f2f5;
  height: auto;
  padding: 14rem 2rem 2rem 5rem;
  /* position: relative; */
}

.no__state--div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
  font-size: clamp(1.5rem, 4vw, 4rem);
}

.data__containers {
  display: flex;
}

.data__management--heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #7a7aa0;
  margin-bottom: 4rem;
}

.data__managment-heading-and-CTAs--span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* background-color: #7a7aa0;  */
  margin-bottom: 2rem;
}

.form__heading-and-close--CTA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.management__CTAs-span {
  display: flex;
  /* width: 100%; */
  /* justify-content: flex-end; */
  flex-wrap: wrap;
}

.add__state-CTA {
  height: 4rem;
  padding: 0 1rem;
  background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
  /* border: 1px solid #e5e5e5; */
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
  color: #ffffff;
  margin-left: 1rem;
  margin-top: 1.5rem;
}

.no__states-text {
  font-weight: 600;
  font-size: clamp(1.5rem, 4vw, 4rem);
  color: #7a7aa0;
}

.addState__form {
  /* height: 20rem; */
  /* width: 50rem; */
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
}

.listed__states--container {
  width: 545px;
  height: 461px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  background-color: #655d8a;
  background: #ffffff;
  border: 0.7px solid #f2f5f9;
  box-sizing: border-box;
  box-shadow: 0px 2px 22px rgb(219 218 218 / 31%);
  border-radius: 15px;
  padding: 4rem 4rem;
  margin-bottom: 3rem;
}

.listed__states-heading {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.state__name-and-edit--CTA {
  display: flex;
  align-items: flex-end;
  height: 3rem;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

.state__name {
  font-size: 1.8rem;
  font-weight: 600;
}

.add__lga--CTA {
  height: 4rem;
  padding: 0 1rem;
  background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
  /* border: 1px solid #e5e5e5; */
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
  color: #ffffff;
  margin-left: 1rem;
}

@media screen and (max-width: 55rem) {
  .listed__states--container {
    padding: 2rem 2rem;
  }
}
@media screen and (max-width: 48rem) {
  .data__management--container {
    padding: 14rem 2rem 2rem 2rem;
  }

  .data__containers {
    display: block;
  }

  .listed__states--container {
    width: auto;
  }
}
