.active__facility-note {
  border: 2px solid rgb(52, 71, 103);
  padding: 1rem 1.5rem;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  font-weight: 600;
  width: fit-content;
  word-spacing: 0.2rem;
  position: fixed;
  backdrop-filter: blur(4px);
  top: 13rem;
  /* text-align: center; */
}
