.user__page--container {
  padding: 12rem 4rem;
  /* height: 100%; */
}

.user__page--heading {
  font-weight: 600;
  font-size: 20px;
  color: #171531;
  margin-bottom: 2rem;
}

.user__deatails--container {
  background-color: #fff;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
  border-radius: 10px;
  /* background-color: #eead06; */
  width: 60%;
  margin-bottom: 4rem;
  height: 50%;
  padding: 3rem;
}

.remove__user--span {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  position: relative;
}

.remove__prompt--con {
  position: absolute;
  background-color: #fff;
  padding: 2rem;
  z-index: 3;
  text-align: center;
  top: 0rem;
  left: 20rem;
  box-shadow: 0px 4px 40px rgba(219, 219, 219, 0.612);
  border-radius: 10px;
}

.remove__prompt--text {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 166.5%;
  text-align: center;
  color: #4a5567;
  margin-bottom: 2rem;
}

.remove__action--CTA {
  width: 54px;
  height: auto;
  border: 0.7px solid #afffb2;
  border-radius: 5px;
  color: #219653;
  background-color: #fff;
  padding: 0.5rem 0;
  margin-right: 0.5rem;
}

.cancel__delete-CTA {
  /* width: 54px; */
  padding: 0.5rem 1rem;
  border: 0.7px solid #ffbdbd;
  border-radius: 5px;
  color: #c40f0f;
}

.image__and__text--details-span {
  display: flex;
}

.user__details--content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user__details--box {
  display: flex;
  justify-content: space-between;
}

.user__profile--picture {
  height: clamp(6rem, 12vw, 12rem);
  width: clamp(6rem, 12vw, 12rem);
  border-radius: 50%;
  margin-right: 4rem;
}

.user__profile--image {
  width: clamp(6rem, 12vw, 12rem);
  height: clamp(6rem, 12vw, 12rem);
  border-radius: 50%;
  margin-right: 2rem;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(1.6rem, 3vw, 3rem);
  font-weight: 600;
  color: #ffffff;
}

.email__label {
  color: #858585;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1rem;
}

.user__profile--name {
  margin-bottom: 3rem;
  font-size: 2rem;
}

.user__email--add {
  color: #858585;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 3rem;
}

.invitation__date {
  color: #858585;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1rem;
}
.invitation__date--num {
  color: #858585;
  font-weight: 300;
  font-size: 14px;
}

.user__profile--status-pending {
  background-color: #fff9c6;
  color: #eead06;
  border-radius: 2rem;
  height: fit-content;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 1.2rem;
}
.user__profile--status-joined {
  background-color: #ccffdd;
  color: #0b7e09;
  border-radius: 2rem;
  height: fit-content;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 1.2rem;
}

.hide__title {
  display: block;
}

.show__arrow {
  display: none;
}

.remove__user--span {
  display: flex;
  align-items: center;
  width: 100%;
}

.remove__user--CTA {
  color: #f95959;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
}

.remove__user--prompt-container {
  width: 23%;
  position: fixed;
  /* left: 0; */
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: center;
  z-index: 3;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.329);
  transition: 200ms ease-in-out;
}

.dont__remove {
  width: 0rem;
  position: fixed;
  /* left: 0; */
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #858585;
  transition: 200ms ease-in-out;
}

.remove__user--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
  border-radius: 1rem;
}

.hide__remove--user-content {
  display: none;
}

.cancel__remove--user {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: 2rem 0;
}

.cancel__icon {
  cursor: pointer;
}

.remove__user--prompt-text {
  margin: 2rem 0;
  text-align: center;
  color: #858585;
  font-size: 1.5rem;
}

.remove__user-and-cancel--CTAs {
  margin: 2rem 0;
}

.yes__remove--CTA {
  background-color: #655d8a;
  padding: 2rem;
  color: #ffffff;
  border-radius: 1rem;
  margin-right: 1rem;
}

.cancel__remove-user--CTA {
  padding: 2rem 4rem;
  color: #989898;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
  border-radius: 1rem;
}

@media screen and (max-width: 78rem) {
  .remove__user--prompt-container {
    width: 30%;
  }
}
@media screen and (max-width: 61.56rem) /*985/16*/ {
  .remove__user--prompt-container {
    width: 44%;
  }
}
@media screen and (max-width: 59.4rem) {
  .user__deatails--container {
    background-color: #fff;
    box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
    border-radius: 10px;
    /* background-color: #eead06; */
    width: 100%;
    height: 50%;
    padding: 3rem;
  }

  .remove__prompt--con {
    top: 0rem;
    left: 8rem;
  }
}
@media screen and (max-width: 41.94rem) {
  .remove__user--prompt-container {
    width: 65%;
  }
}

@media screen and (max-width: 31.56rem) {
  .user__profile--status {
    position: absolute;
    top: 2rem;
    padding: 0.4 0.5rem;
    top: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    bottom: 0px;
    right: 12px;
  }

  .profile__image--span {
    display: flex;
    justify-content: center;
  }

  .user__profile--image {
    margin-right: 0rem;
  }

  .user__profile--image {
    width: clamp(10rem, 12vw, 12rem);
    height: clamp(10rem, 12vw, 12rem);
    margin-bottom: 2rem;
    margin-top: -4rem;
  }

  .user__profile--status-pending {
    position: absolute;
  }

  .user__profile--status-joined {
    position: absolute;
  }

  .user__deatails--container {
    position: relative;
  }

  @media screen and (max-width: 28.38rem) {
    .remove__user--prompt-container {
      width: 100%;
    }

    .user__profile--status-pending {
      top: 5rem;
      left: 26rem;
    }

    .user__profile--status-joined {
      top: 5rem;
      left: 26rem;
    }

    .yes__remove--CTA {
      padding: 1rem 1rem;
    }

    .cancel__remove-user--CTA {
      padding: 1rem 1rem;
    }
  }

  .show__arrow {
    display: block;
    cursor: pointer;
  }

  .hide__title {
    display: none;
  }

  .image__and__text--details-span {
    display: block;
    width: 100%;
    text-align: center;
  }
  .user__details--box {
    position: relative;
  }
  .email__label {
    text-align: left;
  }

  .user__email--add {
    text-align: left;
  }

  .invitation__date {
    text-align: left;
  }

  .invitation__date--num {
    text-align: left;
  }

  .user__profile--picture {
    margin-right: 0;
    height: 10rem;
    width: 10rem;
    margin-top: -3rem;
  }

  .user__deatails--container {
    padding: 3rem 0;
  }

  .user__page--container {
    padding: 2rem;
  }

  .remove__user--span {
    margin-top: 3rem;
  }
}
