.charts__container {
  height: 38rem;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 3rem;
  margin-bottom: 8rem;
}

.chart__title {
  font-size: clamp(1.3rem, 2vw, 2rem);
  margin: 1.5rem 0;
}
