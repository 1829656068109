.employee__management {
  width: 100%;
  padding: 18rem 5.5rem 2rem 5rem;
  display: flex;
  flex-direction: column;
  /* background-color: coral; */
  /* background: rgb(255, 255, 255); */
  color: rgb(52, 71, 103);
  background: #f0f2f5;
  height: auto;
  min-height: 100vh;
}

.data__export-CTA {
  background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
  color: #ffffff;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  margin: 1rem 0;
  border-radius: 15px;
}

.title-and-export--CTA {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.employees__heading {
  color: rgb(52, 71, 103);
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.employees__container {
  background-color: #ffffff;
  height: 100%;
  padding: 2rem;
  width: 100%;
  overflow-x: auto;
  background: #ffffff;
  border: 0.7px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(251, 251, 251, 0.1);
  border-radius: 10px;
  padding-bottom: 4rem;
}

.employees__table {
  border-collapse: collapse;
  width: 100%;
  padding-left: 2rem;
  white-space: nowrap;
  z-index: -1;
  margin-top: 1.5rem;
}

.employees__table tr {
  padding-bottom: 3rem;
}
.employees__table tr > th {
  padding-top: 0rem !important;
}

@media screen and (max-width: 48rem) {
  .employee__management {
    padding: 13rem 2rem 2rem 2rem;
  }
}
