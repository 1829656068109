.login__container {
  /* height: 100vh; */
  /* background-color: cadetblue; */
  padding: 13.4rem 0 5rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.login__content--container {
  height: 100vh;
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.login__imt--logo {
  height: auto;
  width: 17rem;
  margin-top: 1.5rem;
  margin-bottom: 10rem;
}

.navLogo-and-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  height: 8%;
  /* background-color: gold; */
}

.login__page-arrow {
  position: absolute;
  right: 7%;
  top: 8rem;
  bottom: 0;
}

.login__form--container {
  /* width: 50%; */
  background: #fff;
  /* padding: 3rem 0 0 8rem; */
}

.form-and-text__container {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
}

.login__form {
  background-color: blueviolet;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 4rem 5.3rem;
  width: 50%;
  height: fit-content;
  border-radius: 2rem;
  background: #fff;
  box-shadow: 0px 4px 30px 3px rgba(186, 186, 186, 0.25);
}

.mail__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
}

.login__email-and-icon {
  position: relative;
}

.login__password-and-icons {
  position: relative;
}

.lock__icon {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  right: 0;
  bottom: 0;
}

.eye__icon {
  position: absolute;
  top: 1.5rem;
  left: 38.5rem;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.login__form--texts {
  display: flex;
  flex-direction: column;
  /* width: 36rem; */
  /* background-color: aquamarine; */
}

.login__formheading {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 10rem;
  color: #171531;
}

.vaccine__app-span {
  color: #000;
  font-family: Lato;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 5.5rem;
}

.app__text {
  color: #ffa726;
}

.login__form-sub--text {
  width: 606px;
  height: 76px;
}

.login__email--label {
  color: #242424;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.4rem;
}
.login__password--label {
  color: #242424;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.4rem;
}

.login__email--input {
  width: 100%;
  border: none;
  height: 7.6rem;
  padding-left: 2rem;
  margin-bottom: 4.7rem;
  background: #f4f4f4;
}
.email--input {
  width: 429.23px;
  height: 48px;
  padding-left: 5rem;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.forgot__passwordone {
  color: #655d8a;
  margin-top: 3rem;
  font-size: 1.2rem;
  cursor: pointer;
  width: fit-content;
}

.email--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
}
.login__email--input:focus {
  outline: none !important;
  border: 1px solid #ffa726;
}

.login__password--input {
  padding-left: 2rem;
  width: 100%;
  height: 7.6rem;
  background: #f4f4f4;
  border: none;
  box-sizing: border-box;
  margin-bottom: 8rem;
}

.login__password--input:focus {
  outline: none !important;
  border: 1px solid #ffa726;
  background: rgba(178, 178, 178, 0.24);
}

.password--input {
  padding-left: 5rem;
  width: 429.23px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.password--input:focus {
  outline: none !important;
  border: 1px solid #f95959;
}

.login__CTA-span {
  width: 100%;
  padding: 0 7rem;
}

.login__submit--CTA {
  width: 100%;
  border: none;
  box-sizing: border-box;
  border-radius: 2rem;
  font-weight: 400;
  font-size: 2rem;
  background: #2f80ed;
  transition: 0.2s ease-in-out;
  padding: 2.2rem 0rem;
  margin-bottom: 3rem;
}

.remember__me-and-forgot-password--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8.5rem;
}

.remember__me-span {
  display: flex;
}

.forgot__password {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.remember__me-text {
  color: #817e7e;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 1.6rem;
}

input[type="checkbox"] {
  border-radius: 50%;
}

.login__submit--CTA:hover {
  transform: translateY(-0.3rem);
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem,
    rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.login__image--container {
  height: 100%;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));

  /* background-image: url(../../assets/images/Starbg\ 2.png),
    linear-gradient(18.13deg, #9e90de 25.1%, #e1b4e9 61.35%, #f8d2e0 92.91%); */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login__main--text {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  margin-top: 7rem;
  margin-bottom: 2rem;
}

.login__sub--text {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 3rem;
  color: #f8f8f8;
}

.create__comp--CTA-span {
  /* width: 429.23px; */
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 77rem) {
  .login__form {
    width: 71%;
  }
}

@media screen and (max-width: 64rem) /*104/16 = 65*/ {
  .login__container {
    height: fit-content;
  }

  .login__content--container {
    /* height: fit-content; */
    /* background-color: red; */
    display: flex;
  }

  .login__image--container {
    height: auto;
    background-color: #655d8a;
    /* background-image: url("http://localhost:3000/static/media/Starbg%202.0167db5b6d99460bc2d6.png"); */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login__form--container {
    /* padding: 3rem 3rem 0 6rem; */
  }

  .login__formheading {
    font-weight: 600;
    font-size: 25px;
    margin: 2rem 0;
    color: #655d8a;
  }

  .login__email--input {
  }
  .email--input {
  }

  .login__password--input {
  }
  .password--input {
  }

  .dont__have-account {
  }

  .login__submit--CTA {
  }

  .create__comp--CTA-span {
    display: flex;
    justify-content: center;
  }

  .eye__icon {
    left: 32.5rem;
  }
}
@media screen and (max-width: 54.8rem) /*877/16 = 65*/ {
  .login__content--container {
    display: block;
  }

  .login__form--container {
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
  }

  .login__image--container {
    display: none;
  }

  .form-and-text__container {
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .create__comp--acc {
    width: fit-content;
  }
}
@media screen and (max-width: 48rem) /*877/16 = 65*/ {
  .login__form {
    width: 100%;
    position: absolute;
    height: 100vh;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      url(http://localhost:3000/static/media/baby-bg-pic.6de8c7e40384df7695f2.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0;
    background-position: center;
    padding-top: 10.7rem;
    padding-bottom: 10.7rem;
  }

  .login__email--label {
    color: #7c7c7c;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .login__password--label {
    color: #7c7c7c;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .login__email--input {
    background: rgba(178, 178, 178, 0.24);
    height: 6rem;
  }

  .login__password--input {
    background: rgba(178, 178, 178, 0.24);
    height: 6rem;
    margin-bottom: 4rem;
  }

  .login__CTA-span {
    width: 100%;
    padding: 0rem;
  }

  .login__submit--CTA {
    font-size: 2rem;
    font-weight: 400;
    padding: 1.3rem;
  }
  .remember__me-and-forgot-password--container {
    display: block;
    margin-bottom: 6.8rem;
  }

  .remember__me-span {
    margin-bottom: 5rem;
  }

  .forgot__password {
    text-align: center;
  }

  .login__imt--logo {
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 29rem) /*877/16 = 65*/ {
  .login__form--container {
    /* padding: 3rem 3rem 0 2rem; */
    height: 70vh;
  }

  .login__content--container {
    display: block;
  }

  .login__form--container {
    width: 100%;
    height: 100%;
  }

  .login__form {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .login__email--input {
  }
  .email--input {
  }

  .login__password--input {
  }
  .password--input {
  }

  .login__submit--CTA {
  }

  .dont__have-account {
  }

  .create__comp--CTA-span {
  }

  .eye__icon {
    left: 26.5rem;
  }

  .login__image--container {
    display: none;
  }

  .login__form--texts {
  }

  .form-and-text__container {
    display: flex;
  }
}

@media screen and (max-width: 24rem) {
  .login__form {
    padding-top: 20rem;
  }
}
