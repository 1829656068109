.css-cwhad8-MuiDateCalendar-root {
  width: 100% !important;
  margin: 0 !important;
  max-height: fit-content !important;
  height: fit-content !important;
  background-color: #fff !important;
}

.css-flbe84-MuiDayCalendar-weekContainer {
  width: 100% !important;
  margin: 3rem 0 !important;
  /* background: red; */
}

.css-1c32n2y-MuiBadge-root {
  display: flex !important;
  flex: 1 !important;
  align-items: center !important;
  justify-content: center !important;
}

.css-144dnr3-MuiBadge-badge {
  top: 15% !important;
  right: 23% !important;
}

.css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
  color: #242424 !important;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  font-family: "Lato", sans-serif !important;
  color: #242424 !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  width: 100% !important;
}

.css-dplwbx-MuiPickersCalendarHeader-label {
  color: #242424 !important;
  font-family: Lato !important;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Lato", sans-serif !important;
  /* background-color: red !important; */
  font-size: 1.4rem !important;
  color: #242424 !important;
  font-weight: 700 !important;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  font-family: "Lato", sans-serif !important;
  /* background-color: red !important; */
  font-size: 1.4rem !important;
  color: #242424 !important;
  font-weight: 700 !important;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #27ae606b !important;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #27ae606b !important;
  color: #242424 !important;
  font-family: Lato !important;
  font-size: 1.4rem !important;
  font-weight: 700 !important;
}

.taken__vac-icon {
  height: 1.4rem;
  width: 1.6rem;
}

/* .css-144dnr3-MuiBadge-badge {
  top: 45% !important;
  right: 48% !important;
} */

.pending__vac-icon {
  height: 0.7rem !important;
  width: 0.7rem !important;
  /* position: absolute !important;
  top: 131% !important;
  right: 29% !important; */
}

.css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
  display: block;
  position: relative;
  overflow-x: hidden;
  min-height: 36rem !important;
}

@media screen and (min-width: 48rem) {
  .css-cwhad8-MuiDateCalendar-root {
    height: fit-content !important;
    max-height: fit-content !important;
  }

  .css-nk89i7-MuiPickersCalendarHeader-root {
    margin-top: 4rem;
    margin-bottom: 7rem !important;
  }
  .css-dplwbx-MuiPickersCalendarHeader-label {
    color: #242424;
    font-family: Lato !important;
    font-size: 3.2rem !important;
    font-weight: 700 !important;
  }

  .css-flbe84-MuiDayCalendar-weekContainer {
    margin: 8rem 0 !important;
  }

  .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    font-size: 2.2rem !important;
  }

  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
    font-size: 3.2rem !important;
  }

  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    width: 54px !important;
    height: 54px !important;
  }

  .css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    font-size: 3.2rem !important;
    font-weight: 700 !important;
  }

  .css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    font-size: 3.2rem !important;
    font-weight: 700 !important;
  }

  .css-jgls56-MuiButtonBase-root-MuiPickersDay-root {
    width: 5.4rem !important;
    height: 5.4rem !important;
  }

  .css-1vooibu-MuiSvgIcon-root {
    width: 2em !important;
    height: 2em !important;
  }

  .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
    width: 2em !important;
    height: 2em !important;
  }

  .css-144dnr3-MuiBadge-badge {
    top: 15% !important;
    right: 40% !important;
  }

  .taken__vac-icon {
    width: 3rem;
    height: 2.7rem;
  }

  .pending__vac-icon {
    height: 1.1rem !important;
    width: 1.3rem !important;
    /* position: absolute !important;
    top: 131% !important;
    right: 29% !important; */
  }
  /* .css-144dnr3-MuiBadge-badge {
    top: 45% !important;
    right: 48% !important;
  } */

  .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
    min-height: 70rem !important;
  }
}
