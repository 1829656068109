.details__components-container {
  width: 100%;
  padding: 18rem 5.5rem 2rem 5rem;
  display: flex;
  flex-direction: column;
  /* background-color: coral; */
  /* background: rgb(255, 255, 255); */
  color: rgb(52, 71, 103);
  background: #f0f2f5;
  /* justify-content: space-between; */
}

/* .notification__container {
  display: flex;
  width: 100%;
  justify-content: end;
  background-color: red;
} */

.facility__name {
  margin-bottom: 6rem;
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: 700;
  text-align: left;
  width: 100%;
}

.tab__container {
  display: flex;
  margin-bottom: 2rem;
  height: fit-content;
  justify-content: flex-start;
  /* background-color: cadetblue; */
  flex-wrap: wrap;
  width: 100%;
}

.container div {
  flex-shrink: 1;
}

.container div:not(:last-child) {
  margin-right: 2rem;
}

@media screen and (max-width: 78rem) /*991/16 = 61.94rem*/ {
  .details__components-container {
    align-items: center;
  }
}
@media screen and (max-width: 61.94rem) /*991/16 = 61.94rem*/ {
  .details__compponents-container {
    height: fit-content;
  }
}

@media screen and (max-width: 51.88rem) /*830/16 = 51.88rem*/ {
  .container {
    flex-direction: column;
  }
  .container > div {
    margin-bottom: 2rem;
  }

  .container div:not(:last-child) {
    margin-right: 0;
  }
  .tab__container {
    justify-content: center;
  }

  .details__components-container {
    padding: 18rem 3rem 2rem 3rem;
  }
}
