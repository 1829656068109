.userDetails__Bar--container {
  border-bottom: 1px solid #f2f5f9;
  /* background-color: aqua; */
  padding: 1.8rem 8rem 1.9rem 3rem;
  /* height: 5.9rem; */
  background: #ffffff;
  position: fixed;
  width: 83.5%;
  z-index: 5;
}

.search__input-and-icon {
  position: relative;
}

.hide__mobile-menu--icon {
  display: none;
}

.search__bar {
  width: 23rem;
  height: 4rem;
  border-radius: 0.5rem;
  padding-left: 1rem;
  border: 1px solid rgb(152, 152, 152);
}

.search__icon {
  position: absolute;
  top: 1rem;
  left: 19rem;
}

.logout__CTA-and-text {
  display: none;
}

.logout__text {
  display: block;
  margin: 0 1.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #f04545;
}

.userDetails__content--container {
  /* background-color: blueviolet; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mobile__back--nav {
  display: none;
}

.currentLink-and-title {
  /* background-color: tomato;   */
  display: flex;
  align-items: center;
}

.series__and__details {
  display: flex;
}

.current__link {
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  font-weight: 300;
  color: #858585;
}

.current__series {
  /* margin-left: 2rem; */
  font-weight: 400;
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  color: #171531;
}

.current__title {
  font-style: normal;
  font-weight: normal;
  font-size: (1.5rem);
  line-height: 1.9rem;
  color: black;
}

.currentLink-and-title > svg {
  width: 1rem;
  height: 1rem;
  margin: 0 2rem;
}

.page__heading {
  font-style: normal;
  font-size: clamp(1.8rem, 2vw, 2rem);
  font-weight: 400;
  line-height: 25px;
  color: #171531;
}

.notification-and-userName {
  /* background-color: chartreuse; */
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.vac__notification-heading {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: black;
}

.notification__content-container {
  position: absolute;
  /* height: fit-content; */
  /* max-height: 20rem; */
  height: 20rem;
  overflow-y: scroll;
  width: 30rem;
  top: 2rem;
  left: -26rem;
  right: 0rem;
  bottom: 0rem;
  background-color: rgb(73 163 241 / 80%);
  border-radius: 1.5rem;
  padding: 1.3rem;
}

.notification__span {
  width: 100%;
  background-color: #ffffff;
  padding: 1.3rem;
  font-size: 1.3rem;
  cursor: pointer;
  font-weight: 500;
}

.notification__span:not(:last-child) {
  margin-bottom: 1.3rem;
}

.notification-icon-and-number {
  /* background-color: rebeccapurple; */
  display: flex;
  width: fit-content;
  position: relative;
  margin-right: 6rem;
  z-index: 2000000;
}

.notification-icon-and-number > svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: rgb(26, 115, 232);
}

.notification__number {
  font-size: 1rem;
  height: 1.6rem;
  width: 1.6rem;
  color: #ffffff;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
  position: absolute;
  top: -0.8rem;
  left: 0.6rem;
  right: 0;
  bottom: 0;
}

.logout-and-profile__link-CTAs {
  position: absolute;
  top: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  background: #ffffff;
  /* background: red; */
  border: 0.8px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(217, 217, 217, 0.1);
  border-radius: 15px;
  padding: 2rem;
  cursor: pointer;
}

.dropdown__prompts--text {
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  color: #949699;
  margin-left: 2rem;
}

.hide {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
  border-left: 1px solid #f2f5f9;
  border-right: 1px solid #f2f5f9;
  width: 17rem;
  position: relative;
}

.username__abbr {
  font-size: 1rem;
  height: 2.4rem;
  width: 2.4rem;
  color: rgb(26, 115, 232);
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232)); */
  background: #ffffff;
  margin-right: 1rem;
}

.search__CTA {
  height: 4rem;
  padding: 0 1rem;
  background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
  /* border: 1px solid #e5e5e5; */
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
  color: #ffffff;
  margin-left: 1rem;
}

.username-and-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
  border-left: 1px solid #f2f5f9;
  border-right: 1px solid #f2f5f9;
  width: 17rem;
  position: relative;
  z-index: 20;
}

.downarrow__in__detailsBar {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.downarrow__in__detailsBar--flipped {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  transform: rotateX(180deg);
  transition: 0.3s ease-in-out;
}

.username {
  margin-right: 1rem;
  font-weight: 600;
  font-size: 1rem;
}

.username::first-letter {
  text-transform: capitalize;
}

.show__heading {
  display: none;
}
.show__menu--CTA {
  display: none;
}

.hide__name {
  display: block;
}

.mobile__menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  transition: 0.2s ease-in-out;
  padding: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: fit-content;
  background-color: #2f80ed;
  /* backdrop-filter: 2rem; */
  z-index: 20;
}
@media screen and (max-width: 62.5rem) /*768/16*/ {
  /* .username-and-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background: red;
    border-left: 1px solid #f2f5f9;
    border-right: 1px solid #f2f5f9;
    width: 27em;
  } */

  .userDetails__Bar--container {
    padding: 1.8rem 1.9rem;
  }

  .notification-and-userName {
    width: auto;
  }

  .hide {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: red; */
    border-left: 1px solid #f2f5f9;
    border-right: 1px solid #f2f5f9;
    width: 17rem;
  }
}

@media screen and (max-width: 48rem) /*768/16*/ {
  .userDetails__Bar--container {
    border-bottom: 1px solid #f2f5f9;
    /* background-color: aqua; */
    padding: 2.2rem;
    height: fit-content;
    width: 100%;
    transition: 0.2s ease-in-out;
  }

  .hide__mobile-menu--icon {
    display: block;
    padding: 0.4rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
  }

  .hide__abbr {
    display: none !important;
  }

  .mobile__back--nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hide {
    display: none;
  }

  .hide__name {
    display: none;
  }

  .userDetails__content--container {
    /* background-color: blueviolet; */
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .hide__mobile-menu {
    height: 0;
    transition: 0.2s ease-in-out;
  }

  .show__heading {
    display: block;
  }

  .notification-and-userName {
    align-items: center;
  }

  .username-and-arrow {
    width: 12rem;
  }

  .username {
    display: none;
  }

  .mobile__username {
    display: block !important;
    color: #ffffff;
  }

  .hide-notification {
    display: none;
  }

  .notification-icon-and-number > svg {
    width: 2.5rem;
    height: 2.5rem;
    /* fill: #655d8a; */
  }

  .logout__CTA-and-text {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 110px;
    height: 42px;
    left: 0px;
    top: 0px;
    background: #fdfdfd;
    border: 1px solid #f2f5f9;
    border-radius: 8px;
  }

  .logout-and-profile__link-CTAs {
    left: -4rem;
    width: 14rem;
  }

  .search__bar {
    width: 13rem;
    height: 3rem;
    border-radius: 0.5rem;
    padding-left: 1rem;
    border: 1px solid rgb(152, 152, 152);
  }

  .search__icon {
    left: 10rem;
    top: 0.5rem;
  }

  .search__CTA {
    height: 3rem;
    font-size: 1rem;
  }

  .notification-and-userName {
    width: 36%;
  }

  .link__and__icon {
    justify-content: flex-start;
  }

  .mobile__user--data {
    display: flex;
    align-items: center;
    /* padding: 0 2rem; */
    margin-top: 3rem;
  }

  .mobile__menu-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    background-color: #575757a6;
    z-index: 6;
  }
}
@media screen and (max-width: 26rem) /*414/16*/ {
  .series__and__details {
    display: none;
  }
}
