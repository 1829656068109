.all__babies-container {
  background: #f9f9f9;
  padding-top: 7rem;
}

.all__babies-heading-and-notification {
  display: none;
}

.all__babies-mobile--bg {
  position: relative;
  background-image: linear-gradient(
      to right,
      rgba(47, 128, 237, 0.54),
      rgba(47, 128, 237, 0.54)
    ),
    url(../../assets/images/all-babies-img.png);
  min-height: 23.7rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.no__of-babies--box {
  padding: 2rem 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background: #2f80ed;
  margin: 0 auto;
  width: fit-content;
  margin-top: -5rem;
  position: relative;
  z-index: 3;
}

.baby__icon-box {
  width: 4.7rem;
  height: 4.7rem;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -31%;
  left: 10%;
}
.no__of-babies {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
}

.babies__imgs-and-details {
  background: #f9f9f9;
  padding: 5.6rem 3.2rem;
}

.baby__img-and-details {
  border-radius: 2rem;
  border: 1px solid rgba(47, 128, 237, 0.2);
  background: #fff;
  padding: 1.3rem 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

.baby__image-box {
  width: 9rem;
  /* height: 9rem; */
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #2f80ed;
  border-radius: 50%;
  margin-right: 5rem;
}
.baby__detail-img {
  width: 100%;
  border-radius: 50%;
}

.baby__text-details--box {
}

.baby__name-txt {
  color: #242424;
  font-size: 1.8rem;
  font-weight: 700;
}

.other__baby-details--text {
  color: #242424;
  font-size: 1.3rem;
  font-weight: 400;
}

@media screen and (min-width: 48rem) {
  .all__babies-container {
    padding: 7.3rem 3rem;
  }
  .all__babies-heading-and-notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
  }

  .all__babies-heading {
    color: #242424;
    font-size: 4rem;
    font-weight: 700;
  }

  .all__babies-mobile--bg {
    position: relative;
    background-image: linear-gradient(
        to right,
        rgba(47, 128, 237, 0.54),
        rgba(47, 128, 237, 0.54)
      ),
      url(../../assets/images/babies-header-img.png);
    min-height: 23.7rem;
    background-repeat: no-repeat;
    background-position: center;
  }

  .no__of-babies--box {
    margin-top: -16rem;
    padding: 2.4rem 8.4rem;
  }

  .baby__icon-box {
    width: 9.8rem;
    height: 9.8rem;
    top: -59%;
    left: 4%;
  }

  .no__of-babies {
    font-size: 4rem;
    font-weight: 700;
  }

  .babies__imgs-and-details {
    /* display: flex;
    flex-wrap: wrap; */
    padding: 16.6rem 0rem 5.6rem 0rem;
  }

  .baby__img-and-details {
    margin-bottom: 5rem;
    /* width: 47%; */
    padding: 2.1rem 2.9rem;
  }

  .baby__img-and-details:nth-child(odd) {
    margin-right: 2rem;
  }
  .baby__img-and-details:nth-child(even) {
    margin-right: 2rem;
  }

  .baby__name-txt {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
  }

  .other__baby-details--text {
    font-size: 2rem;
    font-weight: 400;
  }

  .other__baby-details--text:not(:last-child) {
    margin-bottom: 2rem;
  }

  .baby__image-box {
    width: 13.7rem;
    height: 13.7rem;
    flex-shrink: 0;
  }
}

/* @media screen and (min-width: 62rem) {
  .all__babies-container {
    padding: 7.3rem 2rem;
  }
  .babies__imgs-and-details {
    flex-direction: column;
  }

  .baby__image-box {
    width: 11.7rem;
    height: 11.7rem;
  }
} */
@media screen and (min-width: 64rem) {
  .all__babies-container {
    padding: 13.3rem 5rem;
  }

  .babies__imgs-and-details {
    display: flex;
    flex-wrap: wrap;
  }

  .baby__img-and-details {
    width: 47%;
  }
}
