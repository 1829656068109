.parentDashboard__container {
  /* height: 100%; */
  /* background-color: red; */
  padding: 10rem 8rem 2rem 5rem;
  background-color: #ffffff;
}

.childBioData__container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

.parent__name-and-notification {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4.7rem;
}

.kids__tab-details-and-image {
  display: flex;
  /* background-color: aqua; */
  margin-bottom: 4.7rem;
}

.welcome__text {
  color: #242424;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.kids__tab-details-and-upcoming--vacs {
  /* background-color: cadetblue; */
  width: 50%;
  display: flex;
  flex: 1;
  padding: 5rem 0rem 7rem 0rem;
  margin-right: 7rem;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
}
.kids__num-tabs {
  display: flex;
  margin-bottom: 5.5rem;
}

.kids__num-tab--one {
  border-radius: 2rem;
  background: #2f80ed;
  padding: 3.1rem 0rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-right: 2.3rem;
  width: 100%;
  /* min-width: 24.5rem; */
  text-align: center;
  height: 11rem;
}
.kids__num-tab--two {
  border-radius: 2rem;
  border: 1px solid #ff6b6b;
  padding: 3.1rem 0rem;
  position: relative;
  background: #fff;
  width: 100%;
  /* min-width: 24.5rem; */
  text-align: center;
  height: 11rem;
}

.kids__num-icon {
  position: absolute;
  top: -6.5rem;
  left: 8%;
  width: 9.3rem;
  height: 9.3rem;
}

.kids__num {
  color: #fff;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.kids__num-two {
  color: #242424 !important;
}

.upcoming__vacs-container {
}

.upcoming__vacs-heading {
  color: #242424;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 3.5rem;
}

.upcoming__vacs-details {
  display: flex;
  border-radius: 20px;
  background: #eff3f9;
  width: fit-content;
  padding: 2.5rem 5rem;
  width: 100%;
  justify-content: space-between;
}

.upcoming__vacs-details--one {
  margin-bottom: 3rem;
}

.upcoming__vac-name {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.upcoming__vac-date {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming__vac-baby--name {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard__img-container {
  /* width: 50%; */
  /* flex: 1; */
  width: 460px;
}

.dashboard__img {
  width: 100%;
  height: auto;
}

.mobile__name-and-notification {
  display: none;
}

/* .mobile__parent-name {
  display: none;
} */

.vaccination__history-heading {
  color: #242424;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 3.6rem;
}

.borderless-table {
  border-collapse: collapse; /* This property collapses the table borders */
  margin-bottom: 5.6rem;
}

.borderless-table,
.borderless-table td,
.borderless-table th {
  border: none; /* Remove border from table, table cells, and table headers */
  font-family: Lato;
}

/* Optional: You can style your table cells and headers as needed */
.borderless-table td,
.borderless-table th {
  padding: 10px;
  text-align: center;
}

/* Optional: Add styling to the table header cells if needed */
.borderless-table th {
  /* background-color: #f0f0f0; */
  border-bottom: 2px solid #000000;
  color: #242424;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.borderless-table tr {
  /* border-right: 1px solid #f0f0f0; */
  border-bottom: none;
}
/* .borderless-table td {
  border-right: 1px solid #f0f0f0;
} */

.border__table-body tr td:not(:last-child) {
  border-right: 0.1px solid #7c7c7c;
}

.table__row-data {
  border-bottom: none !important;
}

.view__full-vac--history {
  /* background-color: red; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 11.7rem;
}

.view__full-vac--CTA {
  color: #2f80ed;
  padding: 1.6rem 6rem;
  border-radius: 3rem;
  border: 1px solid #000;
  background-color: #fff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mobile__vac-history {
  display: none;
}

.next__scheduled-vacs {
  /* background-color: cadetblue; */
}

.next__schedule-vacs--heading {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 3.5rem;
}

.next__-scheduled-vacs--tabs {
  width: 100%;
  margin-bottom: 3rem;
}

.next__scheduled-vac--tab {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2.6rem 9.7rem;
  border-radius: 2rem;
  background: #eff3f9;
}

.last__schedule-tab {
  margin-bottom: 3.5rem;
}

.next__scheduled-vac--tab:not(:last-child) {
  margin-bottom: 1.3rem;
}

.next__scheduled-vac--baby-name {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.next__scheduled-vacs--vac-names {
  display: flex;
}

.next__scheduled-vacs--vac-name {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.next__scheduled-vacs--vac-name:not(:last-child) {
  margin-right: 2.8rem;
}

.next__scheduled-vacs--date {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mobile__scheduled-vacs {
  display: none;
}

.vaccination__information-container {
  /* background-color: #ff6b6b; */
  display: flex;
}

.vaccination__history-vaccines {
  padding-top: 6.5rem;
}

.vaccination__information-heading {
  color: #242424;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5rem;
}

.vaccination__information-tabs {
  margin-right: 2.8rem;
  margin-bottom: 5.8rem;
}

.vaccination__information-tabs--one {
  display: flex;
}

.vaccination__information-tabs--one:not(:last-child) {
  margin-bottom: 2.5rem;
}

.vaccination__information-tab {
  /* padding: 4rem 1.3rem;
  border-radius: 3rem;
  background: #eff3f9; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  border: 1px solid #7c7c7c;
  background: #fff;
  padding: 4rem 1.3rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  /* margin-right: 2.3rem; */
  width: 100%;
  text-align: center;
  height: 10.8rem;
}

.vaccination__information-tab:not(:last-child) {
  margin-right: 2.8rem;
}

.vaccination__tab-text {
  color: #242424;
  font-size: clamp(1.4rem, 1.8vw, 2rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.vac__history-CTA {
  padding: 1.8rem 14.3rem;
}

.health__tips-container {
  overflow-x: scroll;
}

.health__tips-heading {
  color: #242424;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4.5rem;
}
.health__tip-vid--content {
  display: flex;
}

.health__tips-vid--container {
  width: 72rem;
  margin-right: 4.5rem;
}
.health__tips-vid {
  /* width: 100%; */
  /* height: auto; */
  /* background-color: red; */
}

@media screen and (max-width: 81rem) {
  .parentDashboard__container {
    padding: 10rem 4rem 2rem 5rem;
  }

  .dashboard__img-container {
    width: 46%;
  }

  .vac__information-img--container {
    width: 46rem;
  }

  .kids__tab-details-and-upcoming--vacs {
    padding: 3rem 0rem 5rem 0rem;
    margin-right: 4rem;
  }

  .kids__num {
    font-size: 3rem;
  }
  .kids__num {
    font-size: 3rem;
  }
}

@media screen and (max-width: 75rem) {
  .kids__tab-details-and-upcoming--vacs {
    padding: 0rem;
  }

  .kids__num-tab--one {
    height: 9rem;
  }
  .kids__num-tab--two {
    height: 9rem;
  }

  .kids__num {
    font-size: 2.5rem;
  }

  .kids__num-tabs {
    margin-bottom: 3.5rem;
  }

  .kids__num-icon {
    top: -5.5rem;
    width: 7.3rem;
  }
  .vaccination__history-vaccines {
    padding-top: 0;
  }

  .vaccination__information-tabs {
    margin-bottom: 2.8rem;
  }
}

@media screen and (max-width: 64rem) {
  .parentDashboard__container {
    padding: 10rem 2rem 2rem 2rem;
  }

  .kids__num-tabs {
    margin-bottom: 7.5rem;
  }

  .upcoming__vacs-details--one {
    margin-bottom: 2rem;
  }

  .upcoming__vacs-details {
    padding: 1.7rem 5rem;
  }

  .upcoming__vacs-heading {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  .upcoming__vac-name {
    font-size: 1.5rem;
  }

  .upcoming__vac-date {
    font-size: 1.5rem;
  }

  .upcoming__vac-baby--name {
    font-size: 1.5rem;
  }

  .next__scheduled-vac--tab {
    padding: 2.6rem 3.7rem;
  }
}

@media screen and (max-width: 62rem) {
  .vac__information-img--container {
    display: none;
  }

  .vaccination__history-vaccines {
    width: 100%;
    padding: 0rem 3.5rem;
  }

  .vaccination__information-tabs {
    margin-right: 0rem;
  }
  .vaccination__information-tab {
    padding: 3rem 1.3rem;
  }

  .health__tips-container {
    padding: 0rem 3.5rem;
  }
}

@media screen and (max-width: 39rem) {
  .parentDashboard__container {
    padding: 0rem 0rem 2rem 0rem;
  }

  .parent__name-and-notification {
    display: none;
  }

  .kids__num-tabs-and-mobile-name {
    background-image: linear-gradient(
        to right,
        rgba(47, 128, 237, 0.69),
        rgba(47, 128, 237, 0.69)
      ),
      url(../../assets/images/mobile-name-bg.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4.5rem 4rem;
    padding-top: 10rem;
  }

  .mobile__name-and-notification {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8rem;
    align-items: center;
  }

  .mobile__parent-name {
    /* display: block; */
    color: #fff;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .kids__tab-details-and-image {
    display: block;
    margin-bottom: 2.7rem;
  }

  .dashboard__img-container {
    display: none;
  }

  .kids__tab-details-and-upcoming--vacs {
    width: 100%;
    margin-right: 0rem;
  }

  .upcoming__vacs-container {
    padding: 4.5rem 3.5rem;
  }

  .kids__num-tabs {
    margin-bottom: 0rem;
  }

  .kids__num-icon {
    top: -6.5rem;
  }

  .kids__num-tab--one {
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d3e5fb;
  }
  .kids__num-tab--two {
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid #fff;
  }

  .kids__num {
    font-size: 2.4rem;
  }

  .kids__num-one {
    color: #242424;
  }
  .kids__num-two {
    color: #fff !important;
  }

  .vaccination__history-heading {
    font-size: 1.8rem;
    padding: 0 3.5rem;
  }

  .borderless-table {
    display: none;
  }

  .mobile__vac-history {
    display: flex;
    flex-direction: column;
    padding: 0 3.5rem;
    /* background-color: #2f80ed; */
  }

  .mobile__vac-history--baby--names {
    display: flex;
    justify-content: space-between;
    /* background-color: cadetblue; */
    margin-bottom: 2.8rem;
  }

  .vac__history-baby--name {
    color: #7c7c7c;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .active__vac-history-baby--name {
    color: #242424 !important;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .current__child-vac--history {
  }

  .current__child-vac--box {
    padding: 1.7rem 5rem;
    border-radius: 2rem;
    border: 0.5px solid #7c7c7c;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .total__vaccines-box {
    background-color: #eff3f9;
    border: none;
  }

  .current__child-vaccine {
    color: #242424;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .current__child-vaccine-no {
    color: #242424;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .view__full-vac--CTA {
    border: none;
    text-decoration: underline;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    padding: 0rem;
    margin-top: 2rem;
  }
  .next__schedule-vacs--heading {
    color: #242424;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .next__scheduled-vacs {
    padding: 0rem 3.5rem;
  }
  .next__-scheduled-vacs--tabs {
    display: none;
  }

  .mobile__scheduled-vacs {
    display: flex;
  }

  .schedule__vac-baby--names-mobile {
    flex: 1 1;
    display: flex;
    /* background-color: #ff6b6b; */
    flex-direction: column;
    justify-content: space-between;
  }
  .schedule__vac-baby--name-mobile {
    color: #7c7c7c;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .schedule__vac-baby--name-mobile:not(:last-child) {
    margin-bottom: 2.7rem;
  }

  .scheduled__vacs-name-and-date-mobile {
    flex: 2;
    border-radius: 2rem;
    background: #eff3f9;
    display: flex;
    justify-content: space-between;
    padding: 1.3rem 1.4rem 1.3rem 4.7rem;
  }

  .scheduled__vacs-box--mobile {
  }

  .scheduled__vacs-names--box-mobile {
    color: #7c7c7c;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .scheduled__vacs-names--box-mobile:not(:last-child) {
    margin-bottom: 1rem;
  }

  .scheduled__vacs-date--box-mobile {
    display: flex;
    align-items: center;
  }

  .schedule__vacs-date--mobile {
    color: #242424;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .vaccination__history-vaccines {
    width: 100%;
    padding: 0rem 1.5rem;
  }

  .vaccination__information-heading {
    font-size: 1.8rem;
    margin-bottom: 3.2rem;
  }

  .vaccination__information-tabs--one:not(:last-child) {
    margin-bottom: 1rem;
  }

  .vaccination__information-tab:not(:last-child) {
    margin-right: 0.6rem;
  }

  .vaccination__information-tab {
    padding: 1rem 1.3rem;
    height: fit-content;
    border: 1px solid #7c7c7c;
  }

  .vaccination__tab-text {
    /* font-size: ; */
    font-weight: 400;
  }

  .vac__history-CTA {
    margin-top: 0rem;
  }

  .health__tips-heading {
    padding-left: 3.5rem;
    font-size: 2rem;
  }

  .health__tips-vid--container {
    width: 35.8rem;
    /* height: 18.6rem; */
    /* flex-shrink: 0; */
  }

  .view__full-vac--history {
    margin-bottom: 5.7rem;
  }
}
