.dashboard__tab {
  height: 13rem;
  width: 27rem;
  border-radius: 0.8rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
  padding: 2rem 2rem 0 2rem;

  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}

.tab__details-and-icon {
  /* background-color: aquamarine; */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon__div {
  height: 6rem;
  padding: 1rem;
  width: 6rem;
  background-color: cornflowerblue;
  border-radius: 0.8rem;
  margin-top: -4rem;
}

.tab__welcome {
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  text-align: right;
}

.tab__userName {
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: 700;
  text-align: right;
}

@media screen and (max-width: 30rem) {
  .dashboard__tab {
    width: 32rem;
    margin-right: 0rem;
  }

  .icon__div {
    height: 5rem;
    width: 5rem;
  }
}
