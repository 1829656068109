.user__details--form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #655d8a;
  width: 545px;
  height: 547px;
  background: #ffffff;
  border: 0.7px solid #f2f5f9;
  box-sizing: border-box;
  box-shadow: 0px 2px 22px rgb(219 218 218 / 31%);
  border-radius: 15px;
  padding: 4rem 0;
}

.user__details--password-container {
  width: 76%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #655d8a;
  background: #ffffff;
  border: 0.7px solid #f2f5f9;
  box-sizing: border-box;
  box-shadow: 0px 2px 22px rgb(219 218 218 / 31%);
  border-radius: 15px;
  padding: 4rem 0;
}

.userDetails--label {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  color: #949699;
  margin-bottom: 1rem;
}

.profile__eye__icon {
  position: absolute;
  top: 1.5rem;
  left: 92% !important;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.user__details--input {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.save__user--data-CTA {
  width: 429.23px;
  height: 56px;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0.5rem;
  transition: 0.2s ease-in-out;
}

.save__user--data-CTA:hover {
  transform: translateY(-0.3rem);
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem,
    rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.user__data {
  width: 100%;
  height: 48px;
  background: #f8f8f8;
  border: 0.7px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem 2rem;
}

@media screen and (max-width: 40rem) {
  .user__details--form-container {
    width: 100%;
    padding: 4rem 2rem;
  }

  .user__details--password-container {
    width: 100%;
    padding: 4rem 2rem;
  }

  .user__details--input {
    width: 87%;
  }

  .user__data {
    width: 100%;
  }

  .save__user--data-CTA {
    width: 100%;
  }
}
@media screen and (max-width: 26rem) {
  .profile__eye__icon {
    left: 88% !important;
  }
}
@media screen and (max-width: 25rem) {
  /* .profile__eye__icon {
    left: 21.5rem;
  } */
}
@media screen and (max-width: 24rem) {
  /* .profile__eye__icon {
    left: 19.5rem;
  } */
}
