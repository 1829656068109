.notification__container {
  /* position: fixed;
  top: 0;
  left: 0; */
  /* width: 100%; */

  /* padding: 5.2rem 3.5rem; */
  display: flex;
  /* justify-content: flex-end; */
}

.notification__bell-icon {
  cursor: pointer;
}

.notification__content {
  background: #eff3f9;
  height: 100%;
  z-index: 900000;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
}

.notification-and-markall--CTA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.notifications__heading-text {
  color: #242424;
  font-size: 2rem;
  font-weight: 700;
}

.mark__as-read--text {
  color: #242424;
  font-size: 1.2rem;
  font-weight: 700;
}

.notification__descptn-text {
  color: #7c7c7c;
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 2.7rem;
}

.notification__tabs {
  max-height: 100%;
  /* overflow-y: scroll; */
}

.notification__tab {
  border-radius: 2rem;
  background: #fff;
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.notification__check-box {
  width: 2.5rem;
  height: 2.3rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background: #eff3f9;
  margin-right: 1rem;
}

.notification__main-texts {
  display: flex;
  flex-direction: column;
}

.notification__main-text {
  color: #242424;
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.notification__sub-text {
  color: #7c7c7c;
  font-size: 1.1rem;
  font-weight: 400;
}

.notification__status-text {
  color: #ff8f00;
  font-size: 1.1rem;
  font-weight: 700;
}

@media screen and (min-width: 48rem) {
  .notification__container {
    position: relative;
    background-color: transparent;
    justify-content: flex-end;
    /* width: 100%; */
  }

  .notification__content {
    position: absolute;
    border-radius: 20px 0px;
    background: #fff;
    /* background: red; */
    min-width: 61rem;
    max-height: 50rem;
    overflow-y: scroll;
    padding: 3.8rem 6rem;
    top: 7rem;
    left: -57rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    /* width: 79rem; */
    height: fit-content;
    z-index: 200000000000000000000;
  }

  .notification-and-markall--CTA {
    margin-bottom: 5rem;
  }

  .notifications__heading-text {
    font-size: 2.4rem;
    font-weight: 700;
  }

  .mark__as-read--text {
    font-size: 2rem;
    font-weight: 700;
  }

  .notification__descptn-text {
    display: none;
  }

  .notification__check-box {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
  }

  .notification__main-text {
    font-size: 2rem;
  }

  .notification__sub-text {
    font-size: 1.7rem;
  }

  .notification__status-text {
    font-size: 2rem;
  }
}

/* @media screen and (min-width: 62rem) {
  
} */
