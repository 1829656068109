.aefi-input {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 0.7px solid #e5e5e5;
  padding-left: 2rem;
  margin-bottom: 2rem;
}

.aefi-label {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  color: #949699;
  margin-bottom: 1rem;
}

.submit-aefi--CTA {
  /* background-color: blue; */
  margin-right: 1.5rem;
  height: 4.5rem;
  width: 100%;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(
    195deg,
    rgb(73, 163, 241),
    rgb(26, 115, 232)
  );
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: rgb(255, 255, 255);
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
}
