.data__sync-container {
  width: 100%;
  padding: 18rem 5.5rem 2rem 5rem;
  display: flex;
  flex-direction: column;
  /* background-color: coral; */
  /* background: rgb(255, 255, 255); */
  color: rgb(52, 71, 103);
  background: #f0f2f5;
  height: 100%;
}

.data__to__sync {
  width: 80%;
  height: 60%;
  background-color: #ffffff;
  border-radius: 1.5rem;
}

.data__sync--heading {
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 38px;
  color: #171531;
}
