.showVaccines__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: 100vh;
}

.listed__vaccines--container {
  background-color: #fff;
  border-radius: 0.5rem;
  height: fit-content;
  padding-top: 1.5rem;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.close__icon--span {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.vaccine__tab {
  height: 10rem;
  background-color: #f0f2f5;
  border: 0.7px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgba(216, 213, 213, 0.1);
  width: 20rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  transition: 0.2s ease-in-out;
  /* cursor: pointer; */
  padding: 1rem;
}

.vaccine__tab:hover {
  transform: translateY(-0.2rem);
}

.vaccine__tab:not(:last-child) {
  margin-right: 1.5rem;
}

.vac__name-and-update--CTA {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.vac__name-and-date {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: blue; */
}

.edit__icon-and-vac__status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 90%;
}

.vac__name--tag {
  font-size: 1.5rem;
  /* color: "rgb(152, 152, 152)"; */
  font-weight: 600;
}

.vac__status--tag {
  /* padding: 0.5rem; */
  border-radius: 2rem;
}
.update__options {
  position: absolute;
  background-color: #ffffff;
  width: 11rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  top: 2rem;
  left: 2rem;
}

.vac__update-CTA:disabled {
  filter: grayscale();
}

/* .update__options p:hover {
  background: rgb(152, 152, 152);
  background: rgb(196, 65, 65);
} */

@media screen and (max-width: 48rem) {
  .listed__vaccines--container {
    width: 100%;
  }
}
