.child__profile--CTAs {
  position: relative;
  /* background-color: aqua; */
}

.profile__update-options {
  position: absolute;
  top: 5.5rem;
  left: -13rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 146%; */
  background: #ffffff;
  /* background: red; */
  border: 0.8px solid #f2f5f9;
  box-shadow: 0px 2px 22px rgb(26 26 26 / 10%);
  border-radius: 15px;
  padding: 2rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  color: #949699;
  margin-left: 2rem;
}

.acc__update-option {
  background-color: #f0f2f5;
  padding: 0.5rem 1rem;
  color: black;
  display: flex;
  align-items: center;
  /* width: max-content; */
  border-radius: 5px;
}

.acc__update-option:not(:last-child) {
  margin-bottom: 1.5rem;
}
