.full__vac-schedule--container {
  background: #f8f8f8;
  /* height: 100%; */
  padding: 5.2rem 3.5rem 10rem 3.5rem;
}

.full__schedule-heading {
  color: #242424;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
}

.full__schedule-sub--heading {
  color: #7c7c7c;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 5.2rem;
}

.full__schedule-calendar {
  /* height: 30rem; */
  background-color: #fff;
}

.vac__schedules-per--age {
}

.vac__schedule-per--age {
  margin-bottom: 2.7rem;
  display: flex;
  flex-direction: column;
}

.vac__schedule-age--box-red {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 0;
  background: #ff6b6b;
}
.vac__schedule-age--box-yellow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 0;
  background: #ffb95f;
}
.vac__schedule-age--box-green {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 0;
  background: #27ae60;
}

.vac__schedule-age--text {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
}

.vac__schedule-illustration-box-red {
  background: rgba(255, 107, 107, 0.24);
  padding: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vac__schedule-illustration-box-yellow {
  background: rgba(255, 185, 95, 0.24);
  padding: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vac__schedule-illustration-box-green {
  background: rgba(39, 174, 96, 0.24);
  padding: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.baby__at-birth--icon {
  width: 14rem;
  height: 7rem;
}

.vac__schedule-vacs--box {
}

.full__vac-schedule--vac-texts {
  background-color: #fff;
  padding: 2.5rem;
}

.full__vac-schedule--vac-text {
  color: #242424;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.full__schedule-current--day-vacs {
  border: 1px solid #27ae60;
  padding: 2rem 1.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8rem;
}

.full__schedule-current--day-vac {
  color: #242424;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.full__schedule-current--day-vac-date {
  color: #242424;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (min-width: 48rem) {
  .full__vac-schedule--container {
    padding: 10rem 6rem;
  }

  .vac__schedule-per--age {
    flex-direction: row;
    background-color: #fff;
    padding: 4rem 1.4rem;
  }
}
@media screen and (min-width: 55rem) {
  .full__schedule-current--day-vacs {
    padding: 2.9rem 6rem;
  }
}
@media screen and (min-width: 62rem) {
  .full__schedule-heading {
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .full__schedule-sub--heading {
    color: #7c7c7c;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 9rem;
  }

  .full__schedule-current--day-vacs {
    padding: 2.9rem 10rem;
  }

  .full__schedule-current--day-vac {
    font-size: 2rem;
    font-weight: 700;
  }

  .full__schedule-current--day-vac-date {
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .vac__schedule-per--age {
    margin-bottom: 3.5rem;
  }

  .vac__schedule-age-and-illustration {
    margin-right: 4.5rem;
  }

  .vac__schedule-age--box-red {
    padding: 1.6rem 11.5rem;
  }
  .vac__schedule-age--box-green {
    padding: 1.6rem 11.5rem;
  }
  .vac__schedule-age--box-yellow {
    padding: 1.6rem 11.5rem;
  }

  .vac__schedule-illustration-box-red {
    padding: 4rem 4.2rem 2.7rem 4.3rem;
  }
  .vac__schedule-illustration-box-yellow {
    padding: 4rem 4.2rem 2.7rem 4.3rem;
  }
  .vac__schedule-illustration-box-green {
    padding: 4rem 4.2rem 2.7rem 4.3rem;
  }

  .full__vac-schedule--vac-text {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .vac__schedule-age--text {
    font-size: 3rem;
    font-weight: 700;
  }

  .full__vac-schedule--vac-text {
    margin-bottom: 3rem;
  }
}
