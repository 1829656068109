.full__vac-detail--container {
  background: #f9f9f9;
  padding: 4rem 3rem;
}

.full__vac-img--container {
  width: 100%;
  height: fit-content;
}

.vac__img {
  width: 100%;
  max-width: 35rem;
  margin-bottom: 3rem;
}

.full__vac-heading {
  color: #242424;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.full__vac-detail-sub--text {
  color: #7c7c7c;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 5rem;
}

.full__vac-heading-two {
  color: #242424;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}

.full__detail-sub--content {
  background: rgba(47, 128, 237, 0.08);
  padding: 2rem;
}

.full__detail-sub--content--heading {
  color: #242424;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 4.3rem;
}

.full__vac-detail--accordion {
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 1.9rem;
  padding: 1.5rem 0;
  cursor: pointer;
}

.faq__question-main--text {
  color: #242424;
  font-size: 1.5rem;
  font-weight: 700;
}

.faq__question-sub--text {
  color: #7c7c7c;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 1rem;
}

.full__vac-img--container-mobile {
  display: none;
}

@media screen and (min-width: 48rem) {
  .vac__img {
    width: 100%;
    max-width: 50rem;
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 64rem) {
  .full__vac-detail--container {
    display: flex;
    padding-top: 9rem;
  }

  .full__vac-heading {
    font-size: 4rem;
    font-weight: 700;
  }

  .full__vac-heading-two {
    font-size: 2.8rem;
    font-weight: 700;
  }

  .full__vac-detail-sub--text {
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 5rem;
  }

  .full__detail-sub--content {
    border-left: 1px solid #bebebe;
    background-color: #f9f9f9;
    margin-left: 5rem;
  }

  .full__vac-img--container-mobile {
    display: block;
    margin-bottom: 2rem;
  }

  .vac__img {
    display: none;
  }

  .vac__img-mobile {
    display: block;
  }

  .full__detail-sub--content--heading {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  .faq__question-main--text {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .faq__question-sub--text {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
