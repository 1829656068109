.baby__details-container {
  /* padding-top: ; */
  background: #f1f1f1;
  /* background: red; */
  /* height: 100%; */
  padding-bottom: 13rem;
  padding-top: 8rem;
}

.notification-and-baby__data {
  padding: 2.4rem 3.5rem 2.4rem 3.5rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 5.3rem;
}

.baby__image-and-notification {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.7rem;
  /* background-color: red; */
}

.baby__image-and-name {
  display: flex;
  align-items: center;
}

.baby__image-container {
  background-color: #2f80ed;
  padding: 0.5rem;
  border-radius: 50%;
  border-radius: 50%;
  margin-right: 0.5rem;
  height: 65.425px;
  width: 65.425px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.baby__image {
  width: 100%;
  border-radius: 50%;
}

.baby__total-vaccines--box {
  background-color: #2f80ed;
  /* color: #fff; */
}
.baby__total-vaccines--text {
  /* background-color: #2f80ed; */
  color: #fff !important;
}

.baby__name-text {
  color: #242424;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.baby__data-container-mobile {
  display: flex;
  flex-wrap: wrap;
}

.baby__data-boxes {
  display: flex;
  /* background-color: red; */
  width: 100%;
}

.baby__weight-update--box {
}

.baby__weight-update--form {
}

.baby__weight-update--input {
}

.update__baby-weight--CTA {
  background-color: #2f80ed;
  border-radius: 2rem !important;
  color: #fff;
}

.baby__data-boxes:not(:last-child) {
  margin-bottom: 1.7rem;
}

.baby__data-box {
  border-radius: 1rem 0px;
  border: 0.5px solid #2f80ed;
  padding: 0.8rem 1.6rem;
  width: 50%;
}

.baby__data-box:not(:last-child) {
  margin-right: 1.1rem;
}
.baby__data-text {
  color: #242424;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.baby__data-no {
  color: #7c7c7c;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.baby__data-vacs--history {
  padding: 0 3.5rem;
}

.baby__data-vacs--history--heading {
  color: #242424;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 3.2rem;
}

.baby__data-vacs--boxes {
  margin-bottom: 3.3rem;
}

.baby__data-vacs--box {
  width: 100%;
  border-radius: 2rem;
  padding: 2.3rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}

.baby__data-vacs--box:not(:last-child) {
  margin-bottom: 0.8rem;
}

.baby__data-vacs--text {
  color: #242424;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.baby__data-vacs--text-three {
  display: none;
}

.baby__data-vacs--CTA-box {
  display: flex;
  justify-content: center;
}

.baby__data-vacs--CTA {
  border-radius: 3rem;
  border: 1px solid #000;
  color: #2f80ed;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0.8rem 3.2rem;
  margin-bottom: 5.4rem;
}

.baby__data-container {
  display: none;
}

.child__details-content {
  display: flex;
}

@media screen and (min-width: 36rem) {
  .baby__data-vacs--text-three {
    display: block;
    font-size: 1.4rem;
    max-width: 17rem;
  }

  .baby__data-vacs--text-three {
    font-weight: 400;
  }
}

@media screen and (min-width: 48rem) {
  .baby__data-container-mobile {
    display: none;
  }

  .baby__details-container {
    padding: 7rem;
    padding-top: 12rem;
  }

  .notification-and-baby__data {
    padding: 0rem;
  }

  .baby__image-container {
    background-color: #2f80ed;
    margin-right: 3rem;
    height: 7.2rem;
    width: 7.2rem;
  }

  .baby__name-text {
    font-size: 4rem;
    font-weight: 700;
  }

  .notification-and-baby__data {
    background-color: #f1f1f1;
  }

  .baby__data-container {
    display: flex;
    padding: 4.5rem 0.7rem;
    background-color: #fff;
    margin-bottom: 6rem;
  }

  .baby__data-total-and-others {
    background: #f9f9f9;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    margin-right: 2rem;
  }

  .baby__data-other-vacs {
    display: flex;
    width: 100%;
  }

  .baby__data-box {
    border-radius: 0;
    width: 50%;
    padding: 2.1rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .baby__total-vaccines--box {
    width: 100%;
    margin-right: 0rem !important;
    margin-bottom: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .baby__data-text {
    text-align: center;
    color: #242424;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .baby__data-bio-info {
    background: #f9f9f9;
    width: 50%;
    padding: 1.5rem;
  }

  .baby__data-bio-box {
  }
  .baby__data-vacs--history {
    padding: 0rem;
  }

  .baby__data-vacs--text {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 62rem) {
  .baby__data-text {
    font-size: 1.8rem;
  }

  .baby__details-container {
    padding: 7rem 2rem;
    padding-top: 13rem;
  }

  .baby__data-bio-info {
    padding: 3.5rem;
  }

  .baby__data-total-and-others {
    padding: 3.5rem;
  }

  .baby__data-vacs--history--heading {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 6rem;
  }

  .baby__data-vacs--box {
    padding: 1.7rem 2rem 1.7rem 4.9rem;
  }

  .baby__data-vacs--box:not(:last-child) {
    margin-bottom: 3.2rem;
  }

  .baby__data-vacs--text-three {
    color: #242424;
    font-size: 2rem;
    min-width: 30.9rem;
    font-weight: 400;
  }

  .baby__data-vacs--text {
    font-size: 2rem;
  }

  .baby__data-vacs--boxes {
    margin-bottom: 5rem;
  }

  .baby__data-vacs--CTA {
    font-size: 2rem;
    font-weight: 700;
    padding: 1.6rem 6.5rem;
    margin-bottom: 10rem;
  }
}

@media screen and (min-width: 69rem) {
  .baby__details-container {
    padding: 7rem;
    padding-top: 13rem;
  }
}

.childDetails__container {
  background-color: #f0f2f5;
  padding: 12rem 5rem 3rem 4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.child__profile--header {
  border-radius: 1rem;

  /* height: 30rem; */
  width: 90%;
  background-image: linear-gradient(
      195deg,
      rgb(241 73 196 / 73%),
      rgb(26 115 232 / 89%)
    ),
    url(https://images.unsplash.com/photo-1534806391029-791d2695c38b?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170);
  background-position: center;
  background-size: cover;
}

.child__details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
  padding: 0rem 5rem;
}

.child__details--content {
  height: fit-content;
  width: 100%;
  background-color: #ffffff;
  margin-top: -5rem;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
}

.detail {
  margin-bottom: 1.5rem;
  font-size: clamp(1.2rem, 1.2vw, 1.4rem);
}

.childPicture-and-CTAs {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.child__bio--info {
  display: flex;
  flex-direction: column;

  margin-right: 2rem;
}

.username__abbr {
  font-size: clamp(1.2rem, 1.7vw, 1.7rem);
  height: 5rem;
  width: 5rem;
  color: #ffffff;
  display: flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  margin-right: 1rem;
}

.profile__pic-container {
  max-height: 30rem;
  width: 30rem;

  margin-right: 3rem;
  margin-bottom: 3rem;
}

.profile__picture {
  border-radius: 5rem;
  max-width: 100%;
  height: auto;
  max-height: 30rem;
  margin-right: 2rem;
  margin-bottom: 3rem;
}

.child__profile--CTAs {
  display: flex;
  align-items: center;
}

.details__heading {
  margin-bottom: 2.5rem;
  font-size: clamp(1.3rem, 1.7vw, 1.7rem);
}

.profile__CTA {
  margin-right: 1.5rem;
  height: 4.5rem;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(
    195deg,
    rgb(73, 163, 241),
    rgb(26, 115, 232)
  );
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: rgb(255, 255, 255);
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
}

.info__label {
  font-size: clamp(1.2rem, 1.4vw, 1.4rem);
  font-weight: 600;
}

.next__five--vac-span {
  margin-bottom: 2rem;
}

.vac__name {
  font-size: 1.3rem;
}

@media screen and (max-width: 48rem) {
  .childDetails__container {
    padding: 12rem 2rem 3rem 2rem;
  }

  .child__details--content {
    width: 100%;
  }

  .child__profile--CTAs {
    flex-direction: column;
    align-items: flex-end;
  }
  .profile__CTA {
    margin-right: 0rem;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    height: 3.5rem;
  }

  .profile__pic-container {
    max-height: 20rem;
    max-height: 30rem;
    max-width: 30rem;
  }

  .details__heading {
    margin-top: 4rem;
  }
}
