.health__tips-main--container {
  /* background-color: red; */
}

.top__health-tips-and-health__info {
  display: flex;
  flex-direction: column;
}

.health__info-box {
  border-radius: 2rem;
  background: #2f80ed;
  padding: 4.1rem 2.5rem;
  margin-bottom: 6.5rem;
}

.health__tips-box--heading {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.4rem;
}

.health__tips-body--text {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
}

.top__health-tips--container {
}

.top__health-tips--heading {
  color: #242424;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 3.4rem;
  margin-bottom: 1.3rem;
}

.top__health-tips-sub--heading {
  color: #7c7c7c;
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 3.5rem;
}

.top__health-tips--tabs {
}

.top__health-tip--tab {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 1.5rem;
  padding: 1.5rem 0 1.5rem 3.2rem;
  margin-bottom: 1.2rem;
}

.health__tip-icon {
  margin-right: 2rem;
  max-height: 5rem;
  width: 8.8rem;
}

.health__tip-texts--span {
  display: flex;
  flex-direction: column;
}

.health__tip-heading--text {
  color: #242424;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
}

.health__tip-sub--text {
  color: #7c7c7c;
  font-size: 1.1rem;
  font-weight: 400;
}

.common__illnesses-container {
  padding-top: 7rem;
}

.common__illnesses-heading {
  color: #242424;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.common__illnesses-tabs {
  display: flex;
  flex-direction: column;
}

.common__illness-tab {
  border-radius: 2rem;
  border: 1px solid #c3c3c3;
  /* background: rgba(47, 128, 237, 0.2); */
  background: #fff;
  padding: 1.6rem 1rem 1.6rem 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.common__illness-tab--heading {
  color: #242424;
  font-size: 12px;
  font-weight: 700;
  margin-right: 3.8rem;
}

.common__illness-tab--sub-text {
  color: #7c7c7c;
  font-size: 1.1rem;
  font-weight: 400;
}

@media screen and (min-width: 48rem) {
  .health__tips-box--heading {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2.8rem;
  }

  .health__tip-heading--text {
    color: #242424;
    font-size: 2rem;
    font-weight: 400;
  }

  .health__tip-icon {
    margin-right: 3.5rem;
    width: 6.2rem;
    max-height: 5.5rem;
  }

  .health__tips-body--text {
    font-size: 1.6rem;
    font-weight: 400;
  }

  .common__illnesses-heading {
    color: #242424;
    font-size: 2.4rem;
    font-weight: 700;
  }
}

@media screen and (min-width: 64rem) {
  .top__health-tips-and-health__info {
    flex-direction: row-reverse;
  }

  .top__health-tips--container {
    margin-right: 5rem;
  }

  .top__health-tips--heading {
    color: #242424;
    font-size: 2.4rem;
    font-weight: 700;
  }

  .top__health-tips-sub--heading {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 4rem;
  }

  .health__info-box {
    width: 60rem;
    height: fit-content;
    padding: 6rem 3rem 6rem 3rem;
  }

  .top__health-tips--tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .top__health-tip--tab {
    width: 49%;
    padding: 2rem 1rem 2rem 2rem;
  }

  .health__tip-sub--text {
    display: none;
  }

  .common__illnesses-tabs {
    flex-wrap: wrap;
    flex-direction: row;
    /* justify-content: space-between; */
  }

  .common__illnesses-tabs .common__illness-tab:nth-child(odd) {
    margin-right: 1.5rem;
  }
  .common__illnesses-tabs .common__illness-tab:nth-child(even) {
    margin-right: 1.5rem;
  }

  .common__illness-tab {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 5rem 1.5rem 5rem 4rem;
    width: 31%;
  }

  .common__illness-tab--heading {
    color: #242424;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .common__illness-tab--sub-text {
    color: #7c7c7c;
    font-size: 1.4rem;
    font-weight: 400;
  }
}

@media screen and (min-width: 69rem) {
  .top__health-tips--container {
    margin-right: 2rem;
  }
}

/* @media screen and (min-width: 70rem) {
  .vacs-and-healthtips__container {
    padding: 7rem 2rem;
  }

  .top__health-tips--container {
    margin-right: 2rem;
  }
} */
