.baby__weight-chart--container {
  padding: 0 2rem;
}

g > text {
  font-size: 1.3rem !important;
}

svg > text {
  font-size: 1.5rem !important;
}

@media screen and (max-width: 63rem) {
  g > text {
    font-size: 1rem !important;
  }

  svg > text {
    font-size: 1.1rem !important;
  }
}
