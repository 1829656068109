.delete__child-action--container {
  position: relative;
}

.remove__prompt--con {
  position: "absolute" !important;
  top: "-3rem" !important;
  left: "-5rem" !important;
}

.remove__prompt--text {
  font-size: 1.2rem !important;
}

@media screen and (max-width: 48rem) {
  .remove__prompt--con {
  }
}
