.privacy__container {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  text-align: center;
  padding: 4rem 2rem;
}

.privacy__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 50%;
  background-color: #ffffff;
}

.privacy__content h1 {
  margin-bottom: 2rem;
}
.privacy__content p {
  text-align: left;
  font-size: 1.5rem;
  color: #39395f;
}

@media screen and (max-width: 48rem) {
  .privacy__content {
    width: 100%;
  }
}
