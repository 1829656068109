.form-and-text__container--admin {
  height: 92%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 3rem;
  border-radius: 1.5rem;
  width: fit-content;
}
.header-and-close--icon {
  display: flex;
  align-items: center;
}
