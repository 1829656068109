.addEventDetails {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.datePicker__span {
  display: flex;
  /* background-color: red; */
  justify-content: center;
  /* width: 100%; */
}

.date__picker {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.9rem 1rem;
  margin-right: 1rem;
  box-shadow: 0px 2px 22px rgba(212, 212, 212, 0.186);
}

.add__event--CTA {
  margin-top: 1rem;
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 5px;
  background-color: #247dea;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
}

.react-datepicker-popper {
  z-index: 22;
}

.calendar__container {
  width: 100%;
  padding: 0 3.5rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  margin-top: 4rem;
}

.rbc-calendar {
  width: 90%;
}

/* .rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #247dea;
} */

.fc .fc-view-harness-active > .fc-view {
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 4rem;
}
