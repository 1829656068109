.parent__update-switch {
  display: flex;
  /* background-color: red; */
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0rem;
}

.active__parent-switch {
  font-size: 1.5rem;
  color: green;
  text-decoration: underline;
  font-weight: 700;
  /* cursor: pointer; */
}

.inactive__parent-switch {
  font-size: 1.5rem;
  font-weight: 300;
  cursor: pointer;
}
.max__parent-added--text {
  font-size: 1.5rem;
  color: rgb(26, 115, 232);
}
