.sidebar__container {
  /* width: 22%; */
  min-width: 25rem;
  /* background-color: cornflowerblue; */
  background: #2f80ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 5rem;
}

.logout__prompt--container {
  position: absolute;
  /* top: 0; */
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 105;
  justify-content: center;
  align-items: center;
  background: rgba(174, 174, 174, 0.682);
  backdrop-filter: blur(0.7rem);
  -webkit-backdrop-filter: blur(0.7rem);
}

.logout__prompt--content {
  width: 460px;
  height: 333px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(221, 221, 221, 0.2);
  border-radius: 15px;
}

.cancel__logout--icon {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem 0 0;
}

.logout__prompt--text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4a5567;
  margin: 3rem 0;
  font-weight: 500;
}

.logout__icon-and-CTAs {
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80%;
  border-radius: 0 0 15px 15px;
}

.yes__logout {
  width: 175px;
  height: 56px;
  background: #d885a3;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  margin-right: 0.5rem;
}

.cancel__logout {
  width: 175px;
  height: 56px;
  background: #f2f5f9;
  border-radius: 15px;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #949699;
  margin-left: 0.5rem;
}

.auth__company--logo {
  width: 151px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffeff4;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #655d8a;
}

.sidebar__content--container {
  /* background-color: blueviolet; */
  /* height: 75%; */
  display: flex;
  flex-direction: column;
}

.side__nav--links {
  display: flex;
  flex-direction: column;
  /* padding: 0 3rem; */
  /* margin-top: 2rem; */
}

.sidebar__inner--content {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 45%;
}

.dashboard__imt--logo-span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 8%;
  border-bottom: 1px solid #f2f5f9;

  /* background-color: gold; */
}

.dashboard__imt--logo-span {
  height: 7rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.link__and__icon {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  cursor: pointer;
  /* background-color: bisque; */
}

.notColored {
  display: none;
}

.link__and__icon .notColored {
  display: flex;
  align-items: center;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.active {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  cursor: pointer;
  /* background: #efdfe5; */
  border-radius: 5px;
}

.colored {
  display: none;
}

.active .colored {
  display: flex;
  align-items: center;
  fill: #fff !important;
}

.active .link {
  color: #fff;
  font-weight: 700;
  font-size: 1.4rem;
}

.active > svg {
  width: 2rem;
  margin-right: 1.7rem;
}

.link {
  font-size: 1.4rem;
  color: #fff;
  margin-top: 1rem;
  margin-left: 2rem;
  font-weight: 400;
}

.link__and__icon:not(:last-child) {
  margin-bottom: 1.5rem;
}

.link__and__icon:last-child {
  margin-bottom: 1rem;
}

.active:not(:last-child) {
  margin-bottom: 2.5rem;
}
.link__and__icon > svg {
  width: 2rem;
  margin-right: 1.7rem;
}

.logout__CTA {
  margin-bottom: 7rem;
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  width: 100%;
}

.links {
  display: none;
  width: 100%;
}

.mobile__nav-menu {
  display: none;
}
@media screen and (max-width: 48rem) /*768/16*/ {
  .logout__prompt--container {
    display: flex;
  }

  .logout__prompt--content {
    width: 90%;
  }
  .cancel__logout {
    width: 12.5rem;
  }

  .yes__logout {
    width: 12.5rem;
  }

  .sidebar__container {
    width: 100%;
    /* background-color: cornflowerblue; */
    background-color: #fff;
    display: none;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;
    border: 1px solid #f2f5f9;
  }

  .logout__CTA {
    margin-bottom: 0;
    margin: 3rem 0 0 3rem;
  }

  .hidden {
    display: none;
  }

  .active {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    cursor: pointer;
    background: transparent;
    border-radius: 5px;
    color: red;
  }

  /* .link__and__icon > svg {
    fill: #858585;
  } */

  .side__nav--links {
    margin-top: 4rem;
    gap: 2.5rem;
  }

  .mobile__nav {
    /* background-color: red; */
    /* position: fixed; */
    display: none;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 9rem;
    border: none;
    z-index: 100;
    background: transparent;
    box-sizing: border-box;
  }

  .nav-links {
    width: 90%;
    /* background-color: #fff; */
    /* background-color: green; */
    height: 100%;
    /* border: 0.5px solid #f2f5f9; */
    border-radius: 1.5rem;
    display: flex;
    background: transparent;
    /* box-shadow: 0px 2px 22px rgba(185, 185, 185, 0.2); */
    align-items: center;
  }
  .link__and__icon:not(:last-child) {
    margin-bottom: 0;
  }
  .active:not(:last-child) {
    margin-bottom: 0;
  }

  .links {
    display: flex;
    width: 100%;
    /* background-color: tomato; */
    justify-content: space-around;
    border-radius: 1.5rem;
    border: 0.5px solid #f2f5f9;
    box-shadow: 0px 2px 22px rgba(185, 185, 185, 0.2);
  }

  .link__and__icon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .active .colored {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link__and__icon .notColored {
    flex-direction: column;
  }

  .link {
    margin: 0;
  }

  .mobile__nav-menu {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    background: #2f80ed;
    /* height: 14rem; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 5rem;
  }

  .mobile__menu-nav--links {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .link__and__icon {
    padding: 0;
  }

  .link {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .active .link {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .active {
    padding: 0;
  }

  .mobile__icon-one {
    height: 2rem !important;
    width: 2rem !important;
    margin-bottom: 1rem;
  }
  .mobile__icon-two {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
}
